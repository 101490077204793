// A webpack entry point for our common third party vendor code
import 'angular';
import 'angular-animate';
import 'angular-aria';
import 'angular-cookies';
import 'angular-local-storage/dist/angular-local-storage';
import 'angular-moment';
import 'angular-promise-extras';
import 'angular-resource';
import 'angular-route';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-ui-sortable';
import 'angular-uuid';

import 'angulartics/dist/angulartics.min';
import 'angulartics-google-analytics/dist/angulartics-ga.min';

import 'mousetrap';
import 'soundmanager2';
import 'tui-image-editor';

// This looks absurd but 'window.jQuery' gets string replaced by our ProvidePlugin configuration so that it
// works in angular, but we also need to set it globally so sprockets based JS can still use it, so we have
// to have something other than 'window.jQuery' on the left hand side...
window.window.jQuery = jQuery;
